import { Box, Center, Heading, VStack, useTheme } from '@chakra-ui/react';
import {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  ReactNodeArray,
} from 'react';
import CookieConsent from 'react-cookie-consent';
import Head from 'next/head';
import Link from 'next/link';
import { useTranslations } from 'next-intl';

import { AppBar, Footer, Logo } from '@shared/ui';

import styles from './PageLayout.module.css';

export interface PageLayoutProps {
  children?: ReactNode;
  meta: {
    [key: string]: string;
  };
  title:
    | string
    | ReactElement<any, string | JSXElementConstructor<any>>
    | ReactNodeArray;
}

export function PageLayout(props: PageLayoutProps) {
  const { children, meta, title } = props;

  const t = useTranslations('PageLayout');
  const theme = useTheme();

  const metaTitle = [t('pageTitle'), meta?.title].join(' - ');

  return (
    <>
      <Head>
        <title>{metaTitle}</title>
        <meta property="og:title" content={metaTitle} key="meta-title" />
      </Head>
      <main className="app">
        <VStack>
          <AppBar />
          <Box className={styles['logo']} flex="1">
            <Center>
              <Link href="/">
                <a>
                  <Box
                    flexDirection={['column', 'column', 'column', 'row', 'row']}
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: '8',
                      marginTop: '3em',
                    }}
                  >
                    <Logo />
                    <Heading as="h1" className={styles.pageTitle}>
                      {title}
                    </Heading>
                  </Box>
                </a>
              </Link>
            </Center>
          </Box>
          {children}
          <Footer />
          <CookieConsent
            location="bottom"
            buttonText={t('cookiesButtonText')}
            style={{
              alignItems: 'center',
              background: theme.colors.footer['500'],
              color: theme.colors.secondary['600'],
              display: 'flex',
              fontSize: '17px',
              fontWeight: '500',
            }}
            buttonStyle={{
              backgroundColor: theme.colors.primary['500'],
              borderRadius: '5px',
              color: 'white',
              fontSize: '18px',
              margin: '30px',
            }}
          >
            {t.rich('cookiesBannerText', {
              link: (chunks) => (
                <Link href="/privacy">
                  <a>{chunks}</a>
                </Link>
              ),
              span: (chunks) => (
                <span className={styles.cookiesPrivacyText}>{chunks}</span>
              ),
            })}
          </CookieConsent>
        </VStack>
      </main>
    </>
  );
}

export default PageLayout;
