import * as Sentry from '@sentry/nextjs';
import {
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  Image,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import { SignedIn, SignedOut } from '@clerk/nextjs';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { GetStaticPropsContext } from 'next';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';

import { HomeUserStatistics, LastItemsList, LastTagsList } from '@shared/ui';
import { usePrompts, useTags, useUser } from '@shared/data';
import { PageLayout } from '@tbgpt-web/ui';

import styles from './index.module.css';

export const Index = () => {
  const t = useTranslations('Index');
  const router = useRouter();
  const theme = useTheme();
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();

  const { getLastPrompts } = usePrompts();
  const { getLastTags } = useTags();
  const { isLoaded, isSignedIn, limits, stats, user } = useUser();

  // Set Sentry additional context
  if (isLoaded && isSignedIn) {
    Sentry.setUser({
      id: user?.publicMetadata.uuid as string,
      planId: user?.publicMetadata.planId,
    });
  }
  Sentry.setTag('op.page', 'home');
  Sentry.setTag('op.pageType', 'front');

  const meta = {
    title: t('meta.title'),
  };

  return (
    <PageLayout meta={meta} title={t('title')}>
      <SignedIn>
        <Box>
          <Grid
            pt={0}
            width="100%"
            templateRows="repeat(1, 1fr)"
            templateColumns={{ base: 'repeat(10, 1fr)', xl: 'repeat(12, 1fr)' }}
            columnGap={{ base: 0, lg: '50px' }}
            rowGap="50px"
          >
            <GridItem
              className={styles.statistics}
              colSpan={{ base: 10, xl: 4 }}
              colStart={{ base: 1, xl: 2 }}
              px="10px"
            >
              <HomeUserStatistics
                limitStatistics={limits}
                userStatistics={stats}
              />
            </GridItem>
            <GridItem
              colSpan={{ base: 10, xl: 6 }}
              colStart={{ base: 1, xl: 6 }}
              px="10px"
            >
              <Box
                display="flex"
                flexDirection="column"
                w="100%"
                justifyContent="space-around"
              >
                <LastItemsList
                  items={getLastPrompts(5)}
                  title={t('lastPrompts')}
                  type="prompt"
                  textEmpty={t('emptyMessages.noPrompt')}
                />
                <LastTagsList
                  items={getLastTags(5)}
                  title={t('lastTags')}
                  type="tag"
                  textEmpty={t('emptyMessages.noTag')}
                />
              </Box>
            </GridItem>
          </Grid>
        </Box>
      </SignedIn>
      <SignedOut>
        <Box
          p={{ base: '10px', lg: 8 }}
          maxW="900px"
          m="auto"
          textAlign="center"
        >
          <Text fontSize="lg" mb={8}>
            {t.rich('introduction')}
          </Text>
          <Box mb={8}>
            <h2 className={styles.subtitle}>{t('howHelp.title')}</h2>
            <List>
              {t.rich('howHelp.items', {
                li: (chunks) => (
                  <ListItem>
                    <ListIcon as={ChevronRightIcon} color="primary.500" />
                    {chunks}
                  </ListItem>
                ),
              })}
            </List>
          </Box>
          <Box mb={8}>
            <h2 className={styles.subtitle}>{t('freeOffer.title')}</h2>
            <Text mb={4}>{t.rich('freeOffer.content')}</Text>
            <Button onClick={() => router.push('/sign-up')} variant="primary">
              {t('freeOffer.button')}
            </Button>
          </Box>
          <Box mb={8}>
            <h2 className={styles.subtitle}>{t('discoverInterface.title')}</h2>
            <Box
              display="flex"
              justifyContent="center"
              flexDirection={{ base: 'column', xl: 'row' }}
            >
              <Box>
                <Button
                  bg="none"
                  border="none"
                  height="none"
                  className="ButtonImage"
                  minWidth="none"
                  px={{ base: 0, lg: '16px' }}
                  _hover={{
                    '&:hover': { bg: 'none' },
                  }}
                  onClick={onOpen1}
                >
                  <Image
                    alt={t('discoverInterface.altImage1')}
                    maxWidth={{ base: '350px', xl: '600px' }}
                    mx={2}
                    src="/img/screen-1.png"
                  />
                </Button>
                <Modal
                  isCentered
                  isOpen={isOpen1}
                  onClose={onClose1}
                  size={theme.sizes.xl}
                >
                  <ModalOverlay />
                  <ModalContent m="10%" p="2%">
                    <ModalCloseButton />
                    <ModalBody>
                      <Center>
                        <Image
                          alt={t('discoverInterface.altImage1')}
                          margin="auto"
                          maxHeight="90%"
                          src="/img/screen-1.png"
                          width="90%"
                        />
                      </Center>
                    </ModalBody>
                  </ModalContent>
                </Modal>
              </Box>
              <Box>
                <Button
                  bg="none"
                  border="none"
                  height="none"
                  className="ButtonImage"
                  minWidth="none"
                  px={{ base: 0, lg: '16px' }}
                  _hover={{
                    '&:hover': { bg: 'none' },
                  }}
                  onClick={onOpen2}
                >
                  <Image
                    alt={t('discoverInterface.altImage2')}
                    maxWidth={{ base: '350px', xl: '700px' }}
                    height={{ base: 'auto', xl: '480px' }}
                    mx={2}
                    src="/img/screen-2.png"
                  />
                </Button>
                <Modal
                  isCentered
                  isOpen={isOpen2}
                  onClose={onClose2}
                  size={theme.sizes.xl}
                >
                  <ModalOverlay />
                  <ModalContent m="10%" p="2%">
                    <ModalCloseButton />
                    <ModalBody>
                      <Center>
                        <Image
                          alt={t('discoverInterface.altImage2')}
                          margin="auto"
                          maxHeight="90%"
                          src="/img/screen-2.png"
                          width="90%"
                        />
                      </Center>
                    </ModalBody>
                  </ModalContent>
                </Modal>
              </Box>
            </Box>
          </Box>
          <h2 className={styles.subtitle}>{t('paidOffer.title')}</h2>
          <Text mb={8}>{t.rich('paidOffer.content')}</Text>
          <Button
            onClick={() => router.push('/subscribe')}
            variant="primary"
            size={{ base: 'sm', lg: 'lg' }}
          >
            {t('paidOffer.button')}
          </Button>
        </Box>
      </SignedOut>
    </PageLayout>
  );
};

export default Index;

export const getStaticProps = async (context: GetStaticPropsContext) => {
  return {
    props: {
      messages: (await import(`../../../messages/${context.locale}.json`))
        .default,
    },
  };
};
